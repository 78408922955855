import React, {useState, useEffect} from "react"
import {Input, Button} from 'antd';
import {LoginOutlined} from '@ant-design/icons';
import UserService from "../service/UserService";
import {errorNotification} from '../components/Notification'
import {
    useLocation,
    useHistory, useNavigate
} from "react-router-dom";
import './css/FormStyles.css';

const inputFieldStyle = {marginBottom: '10px', width: '20em', paddingLeft: '1em'};

const LoginForm = () => {
    let [username, setUsername] = useState(() => '')
    let [password, setPassword] = useState(() => '')


    const loginClicked = () => {
        routeChange()
    }

    const routeChange = () => {
        navigate("/");
    }

    const navigate = useNavigate();


    return (
        <div
             style={{
                 margin: '2.5em',
             }}>
            <div style={{width: '100%'}}>
                <Input type="text"
                       autoFocus
                       name="username"
                       placeholder="email"
                       size="large"
                       value={username}
                       onChange={e => setUsername(e.target.value)}
                       style={inputFieldStyle}
                       onPressEnter={loginClicked}
                />
            </div>
            <div style={{width: '100%'}}>
                <Input type="password"
                       name="password"
                       placeholder="password"
                       size="large"
                       value={password}
                       onChange={e => setPassword(e.target.value)}
                       style={inputFieldStyle}
                       onPressEnter={loginClicked}
                />
            </div>

            <br/>
            <button
                onClick={loginClicked}
                type="primary"
                style={{
                    borderColor: 'white',
                    fontSize: 20, padding: '2.5%', paddingLeft: '5%', paddingRight: '5%'
                }}
            >
                Sign In
            </button>
        </div>
    );
}

export default LoginForm;
