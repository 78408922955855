import React from 'react'
import AnurupPic from './../../img/Anurup2.jpg';
import DickPic from './../../img/Dick.jpg'
import KylePic from './../../img/Kyle.jpg'
import TowniePic from './../../img/Townie.jpg'
import {FriendsCommentsItem} from "./FriendsComments/FriendsCommentsItem";


export const FriendsComments = (props) => {

    let ContainerStyle = {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginTop: '2.5%',
        marginBottom: '2.5%'
    }

    if(props.showNotFriendsExtendedNetworkMessage === true || props.showBlockedExtendedNetworkMessage === true){
        ContainerStyle = {display: 'none'}
    }

    return <div style={ContainerStyle}>
        <div style={{
            backgroundColor: 'rgb(246,204,159)',
            color: 'rgb(201,90,35)',
            fontSize: 16,
            textAlign: 'left',
            paddingLeft: '5%',
            fontWeight: 'bold',
            marginRight: '2.5%'
        }}>
            Matt's Friends Comments
        </div>
        <div style={{textAlign: 'left', paddingLeft: '5%', marginTop: '1%'}}>Displaying <button
            style={{color: 'rgb(201,90,35)', background: 'none', border: 'none', padding: 0}}
           >4</button> of{' '}
            <button
                style={{color: 'rgb(201,90,35)', background: 'none', border: 'none', padding: 0}}
                >4</button>
            {' '}comments
            (<button
                style={{color: 'blue', background: 'none', border: 'none', padding: 0, cursor: 'pointer'}}
            >View All</button>)

        </div>
        {/*friend space item container*/}
        <div style={{marginLeft: '2.5%', marginRight: '2.5%', marginTop: '2.5%'}}>
            <FriendsCommentsItem name={'Townie'} picture={TowniePic} title={'3 hours ago'} body={'Yoooooooo.  You left your phone in my truck last night.  I\'ll swing by in a bit and drop it off.'}/>
            <FriendsCommentsItem name={'Kyle'} picture={KylePic} title={'1 month ago'} body={'Just made some brisket on the smoker you gave us! It took me an entire afternoon to scrape all the mold out of it from last summer, but it turned out good.'}/>
            <FriendsCommentsItem name={'Dick'} picture={DickPic} title={'3 months ago'} body={'I had a blast in Egypt, big guy! Don\'t go disappearing again, theres lots of scuba diving to do next summer.'}/>
            <FriendsCommentsItem name={'Anurup'} picture={AnurupPic} title={'2 years ago'} body={'Thanks for helping teach my programming class the other day.  I really liked your explanation of what it means for Java to be a pass-by-value language.  Are you free on thursday? There is a new restaurant that just opened in Little Italy that we could check out.'}/>

        </div>
    </div>
}

