import React from 'react'
import {TopMenuLinkSeparator} from "./TopMenuLinkSeparator";
import './../HomeView/ViewPicsAndVideosLinks/ViewPicsAndVideosLinks.css'
import {websiteUrl} from "../../service/_BackendUrls";

export const ViewPicsAndVideosLinks = (props) => {

    let NotFriendsStyle = {
    }

    if(props.showNotFriendsExtendedNetworkMessage === true || props.showBlockedExtendedNetworkMessage === true){
        NotFriendsStyle = {display: 'none'}
    }

    return <div className='ViewPicsAndVideosLinksContainer' style={NotFriendsStyle}>
        View My:
        <a href={websiteUrl + '/pics'}
           style={{paddingLeft: "1%", paddingRight: '1%'}}>pics</a>
        |
        <a href={websiteUrl + '/videos'}
           style={{paddingLeft: "1%"}}>videos</a>
    </div>
}

