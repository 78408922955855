import React, {useState} from 'react';
import '../components/HomeView/Home.css'
import {TopMenuLinks} from "../components/HomeView/TopMenuLinks";
import {ExtendedNetworkMessage} from "../components/HomeView/ExtendedNetworkMessage";
import {ProfilePictureSection} from "../components/HomeView/ProfilePictureSection";
import {ViewPicsAndVideosLinks} from "../components/HomeView/ViewPicsAndVideosLinks";
import {ContactingMe} from "../components/HomeView/ContactingMe";
import {MattSpaceUrl} from "../components/HomeView/MattSpaceUrl";
import {Blurbs} from "../components/HomeView/Blurbs";
import {FriendSpace} from "../components/HomeView/FriendSpace";
import {FriendsComments} from "../components/HomeView/FriendsComments";
import {Details} from "../components/HomeView/Details";
import {LatestBlogPosts} from "../components/HomeView/LatestBlogPosts";
import {TopMenuLogo} from "../components/HomeView/TopMenuLogo";
import {MobileSearchBar} from "../components/HomeView/MobileSearchBar";
import {
    MobileExtendedNetworkMessage
} from "../components/HomeView/MobileExtendedNetworkMessage/MobileExtendedNetworkMessage";


export const HomeView = () => {

    const [showNotFriendsExtendedNetworkMessage, setShowNotFriendsExtendedNetworkMessage] = useState(false);
    const [showFavoritedExtendedNetworkMessage, setShowFavoritedExtendedNetworkMessage] = useState(false);
    const [showBlockedExtendedNetworkMessage, setShowBlockedExtendedNetworkMessage] = useState(false);
    function createHeart() {
        const heart = document.createElement('div');
        const heartSize = (Math.random() * 100);
        heart.classList.add('heart');
        heart.style.fontSize = heartSize + "px";
        heart.style.opacity = '.5';

        heart.style.left = Math.random() * 100 + "vw";
        heart.style.animationDuration = Math.random() * 2 + 1 + "s";

        heart.innerText = '💗';

        document.body.appendChild(heart);

        setTimeout(() => {
            heart.remove();
        }, 5000);
    }

    function startHeartDrop() {
        let timerId = setInterval(createHeart, 10);
        setTimeout(() => clearTimeout(timerId), 2000);
    }

    let CursorStyle = {};

    if(showFavoritedExtendedNetworkMessage === true){
        CursorStyle = {cursor: 'url("heartCursor.png"), auto'}
    }

    return <div className='PageBackground' style={CursorStyle}>
        <TopMenuLogo/>
        <MobileSearchBar/>
        <TopMenuLinks/>
        <div className='BodyContainer'>
            <MobileExtendedNetworkMessage showNotFriendsExtendedNetworkMessage={showNotFriendsExtendedNetworkMessage}
                                          showFavoritedExtendedNetworkMessage={showFavoritedExtendedNetworkMessage}
                                          showBlockedExtendedNetworkMessage={showBlockedExtendedNetworkMessage}/>
            <div className='LeftColumn'>
                <ProfilePictureSection showFavoritedExtendedNetworkMessage={showFavoritedExtendedNetworkMessage}/>
                <ViewPicsAndVideosLinks showNotFriendsExtendedNetworkMessage={showNotFriendsExtendedNetworkMessage}
                                        showBlockedExtendedNetworkMessage={showBlockedExtendedNetworkMessage}/>
                <ContactingMe showNotFriendsExtendedNetworkMessage={showNotFriendsExtendedNetworkMessage}
                              setShowNotFriendsExtendedNetworkMessage={setShowNotFriendsExtendedNetworkMessage}
                              showFavoritedExtendedNetworkMessage={showFavoritedExtendedNetworkMessage}
                              setShowFavoritedExtendedNetworkMessage={setShowFavoritedExtendedNetworkMessage}
                              showBlockedExtendedNetworkMessage={showBlockedExtendedNetworkMessage}
                              setShowBlockedExtendedNetworkMessage={setShowBlockedExtendedNetworkMessage}
                              startHeartDrop={startHeartDrop}
                />
                <MattSpaceUrl/>
                <Details showNotFriendsExtendedNetworkMessage={showNotFriendsExtendedNetworkMessage}
                         showBlockedExtendedNetworkMessage={showBlockedExtendedNetworkMessage}/>
            </div>
            <div className='RightColumn'>
                <ExtendedNetworkMessage showNotFriendsExtendedNetworkMessage={showNotFriendsExtendedNetworkMessage}
                                        showFavoritedExtendedNetworkMessage={showFavoritedExtendedNetworkMessage}
                                        showBlockedExtendedNetworkMessage={showBlockedExtendedNetworkMessage}/>
                <LatestBlogPosts showNotFriendsExtendedNetworkMessage={showNotFriendsExtendedNetworkMessage}
                                 showBlockedExtendedNetworkMessage={showBlockedExtendedNetworkMessage}/>
                <Blurbs showNotFriendsExtendedNetworkMessage={showNotFriendsExtendedNetworkMessage}
                        showBlockedExtendedNetworkMessage={showBlockedExtendedNetworkMessage}/>
                <FriendSpace showNotFriendsExtendedNetworkMessage={showNotFriendsExtendedNetworkMessage}
                             showBlockedExtendedNetworkMessage={showBlockedExtendedNetworkMessage}/>
                <FriendsComments showNotFriendsExtendedNetworkMessage={showNotFriendsExtendedNetworkMessage}
                                 showBlockedExtendedNetworkMessage={showBlockedExtendedNetworkMessage}/>
            </div>
        </div>
    </div>
}

