import React from 'react';
import '../components/HomeView/Home.css'
import {TopMenuLinks} from "../components/HomeView/TopMenuLinks";
import {TopMenuLogo} from "../components/HomeView/TopMenuLogo";
import skullImage from "./../img/skull2.png"
import {MobileSearchBar} from "../components/HomeView/MobileSearchBar";
import './../components/PageNotFoundView/PageNotFoundView.css'
import {useLocation} from "react-router-dom";

export const ImATeapotView = (props) => {

    const { state } = useLocation();

    return <div className='PageBackground'>
        <TopMenuLogo/>
        <MobileSearchBar/>
        <TopMenuLinks/>
        <div style={{textAlign: 'center', paddingTop: '5%', paddingLeft: '5%', paddingRight: '5%'}}>
            <div style={{color: 'black', fontSize: 25}}>Error: Failed to launch MattSpace Instant Messenger</div>
            <div style={{color: 'black', fontSize: 20}}>HTTP Response Status Code: 418 [I'm a teapot]</div>
            <img src={skullImage} className='SkullImage'/>
        </div>
    </div>
}

