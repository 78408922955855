import React from 'react'
import {TopMenuLinkSeparator} from "./TopMenuLinkSeparator";

export const Blurbs = (props) => {

    let ContainerStyle = {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    }

    if(props.showNotFriendsExtendedNetworkMessage === true || props.showBlockedExtendedNetworkMessage === true){
        ContainerStyle = {display: 'none'}
    }

    return <div style={ContainerStyle}>
        <div style={{backgroundColor: 'rgb(246,204,159)', color: 'rgb(201,90,35)', fontSize: 16, textAlign: 'left', paddingLeft: '5%', fontWeight: 'bold', marginRight: '2.5%'}}>
            Matt's Blurbs
        </div>
        <div style={{color: 'rgb(201,90,35)', fontSize: 16, textAlign: 'left', paddingLeft: '5%', marginTop: '1%', fontWeight: 'bold'}}>About Me:</div>
        <div style={{textAlign: 'left', paddingLeft: '5%', marginTop: '1%', marginRight: '2.5%'}}>I'm a pretty cool dude. You can probably find me at the beach.  I'm into scuba diving and painting and driving around the country to visit the people I love.</div>
        <div style={{color: 'rgb(201,90,35)', fontSize: 16, textAlign: 'left', paddingLeft: '5%', marginTop: '1%', fontWeight: 'bold'}}>Who I'd like to Meet:</div>
        <div style={{textAlign: 'left', paddingLeft: '5%', marginTop: '1%', marginRight: '2.5%'}}>Do people really fill these out? X)</div>
    </div>
}

