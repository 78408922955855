import React from 'react'

export const DetailsItem = (props) => {

    return <div style={{
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'left',
        marginTop: '.5%',
        paddingLeft: '1%',
        paddingRight: '1%',
        width: '100%',
        background: 'none',
        flexDirection:'row',
        display:'flex',
    }}>
        <div style={{color: 'rgb(48,93,150)', backgroundColor:'rgb(207,222,245)', flex: 1, padding: '1%', marginRight: '.5%', alignSelf: 'stretch'}}>{props.label}</div>
        <div style={{backgroundColor: 'rgb(229,235,243)', flex: 2, padding: '1%'}}>{props.value}</div>
    </div>
}

