import React, {useEffect, useState} from 'react'
import {TopMenuLinkSeparator} from "./TopMenuLinkSeparator";
import {FriendSpaceItem} from "./FriendSpace/FriendSpaceItem";
import AbbiePic from '../../img/Abbie.jpg';
import RileyPic from '../../img/Riley.jpg';
import DavinciPic from './../../img/Davinci.jpg'
import LucyPic from './../../img/Lucy.jpg'
import BrooklynPic from './../../img/Brooklyn.jpg'
import {FriendsCommentsItem} from "./FriendsComments/FriendsCommentsItem";
import TowniePic from "../../img/Townie.jpg";
import KylePic from "../../img/Kyle.jpg";
import DickPic from "../../img/Dick.jpg";
import AnurupPic from "../../img/Anurup2.jpg";


export const FriendSpace = (props) => {

    const defaultSubtitleMessage = <div style={{textAlign: 'left', paddingLeft: '5%', marginTop: '1%'}}>Matt has <button
        style={{color: 'rgb(201,90,35)', background: 'none', border: 'none', padding: 0, cursor: 'pointer'}}
        onClick={() => displayAllFriends()}>9</button> friends
    </div>;

    const [subtitleMessage, setSubtitleMessage] = useState(defaultSubtitleMessage);


    const [friendSpaceItems, setFriendSpaceItems] = useState([
        <FriendSpaceItem name={'Abbie'} picture={AbbiePic}/>,
        <FriendSpaceItem name={'Brooklyn'} picture={BrooklynPic}/>,
        <FriendSpaceItem name={'Lucy'} picture={LucyPic}/>,
        <FriendSpaceItem name={'Davinci'} picture={DavinciPic}/>,
        <FriendSpaceItem name={'Riley'} picture={RileyPic}/>,
    ]);


    const displayAllFriends = () => {
        setFriendSpaceItems([
            <FriendSpaceItem name={'Abbie'} picture={AbbiePic}/>,
            <FriendSpaceItem name={'Anurup'} picture={AnurupPic}/>,
            <FriendSpaceItem name={'Brooklyn'} picture={BrooklynPic}/>,
            <FriendSpaceItem name={'Davinci'} picture={DavinciPic}/>,
            <FriendSpaceItem name={'Dick'} picture={DickPic}/>,
            <FriendSpaceItem name={'Kyle'} picture={KylePic}/>,
            <FriendSpaceItem name={'Lucy'} picture={LucyPic}/>,
            <FriendSpaceItem name={'Riley'} picture={RileyPic}/>,
            <FriendSpaceItem name={'Townie'} picture={TowniePic}/>,
        ]);

        setSubtitleMessage(defaultSubtitleMessage);
    }

    const displayOnlineFriends = () => {
        setFriendSpaceItems([<FriendSpaceItem name={'Riley'} picture={RileyPic}/>, <FriendSpaceItem name={'Townie'} picture={TowniePic}/>]);

        setSubtitleMessage(<div style={{textAlign: 'left', paddingLeft: '5%', marginTop: '1%'}}>Matt has <button
            style={{color: 'rgb(201,90,35)', background: 'none', border: 'none', padding: 0, cursor: 'pointer'}}
            onClick={() => displayAllFriends()}>2</button> online friends
        </div>)
    }

    const displayNewFriends = () => {
        setFriendSpaceItems([<FriendSpaceItem name={'Davinci'} picture={DavinciPic}/>]);

        setSubtitleMessage(<div style={{textAlign: 'left', paddingLeft: '5%', marginTop: '1%'}}>Matt has <button
            style={{color: 'rgb(201,90,35)', background: 'none', border: 'none', padding: 0, cursor: 'pointer'}}
            onClick={() => displayAllFriends()}>1</button> new friend
        </div>)
    }

    let ContainerStyle = {
        marginTop: '2.5%',
    }

    if(props.showNotFriendsExtendedNetworkMessage === true || props.showBlockedExtendedNetworkMessage === true){
        ContainerStyle = {display: 'none'}
    }

    return <div style={ContainerStyle}>
        <div style={{
            backgroundColor: 'rgb(246,204,159)',
            color: 'rgb(201,90,35)',
            fontSize: 16,
            textAlign: 'left',
            paddingLeft: '5%',
            fontWeight: 'bold',
            marginRight: '2.5%'
        }}>
            Matt's Friend Space (Top 5)
        </div>
        {subtitleMessage}
        {/*friend space item container*/}
        <div style={{marginLeft: '2.5%', marginRight: '2.5%'}}>
            {friendSpaceItems.map((item, key) => item)}
        </div>
        <div style={{
            textAlign: 'right',
            paddingRight: '2.5%',
            paddingBottom: '2.5%',
            flexDirection: 'row',
            display: 'flex-end',
        }}>
            <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'end'}}>
                <div>View Matt's Friends:</div>
                <div onClick={() => displayAllFriends()}
                     style={{paddingLeft: "1%", paddingRight: '1%', color: 'blue', cursor: 'pointer'}}>All
                </div>
                <div>|</div>
                <div onClick={() => displayOnlineFriends()}
                     style={{paddingLeft: "1%", paddingRight: '1%', color: 'blue', cursor: 'pointer'}}>Online
                </div>
                <div>|</div>
                <div onClick={()=>displayNewFriends()} style={{paddingLeft: "1%", paddingRight: '1%', color: 'blue', cursor: 'pointer'}}>New</div>
            </div>
        </div>
    </div>
}

