import React from 'react';
import '../components/HomeView/Home.css'
import {TopMenuLinks} from "../components/HomeView/TopMenuLinks";
import {TopMenuLogo} from "../components/HomeView/TopMenuLogo";
import skullImage from "./../img/skull2.png"
import {MobileSearchBar} from "../components/HomeView/MobileSearchBar";
import './../components/PageNotFoundView/PageNotFoundView.css'
import {DetailsItem} from "../components/HomeView/Details/DetailsItem";

export const HelpView = (props) => {

    return <div className='PageBackground'>
        <TopMenuLogo/>
        <MobileSearchBar/>
        <TopMenuLinks/>
        <div style={{textAlign: 'center', paddingTop: '5%'}}>
            <div style={{
                fontWeight: 'bold',
                backgroundColor: 'rgb(246,204,159)',
                color: 'rgb(201,90,35)',
                fontSize: 25,
                textAlign: 'left',
                paddingLeft: '5%'
            }}>F.A.Qs
            </div>
            <div style={{border: '2px solid rgb(48,93,150)',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'left',
                margin: '2.5%',
                marginTop: '5%',}}>
                <div style={{
                    textAlign: 'left',
                    flex: '0 0 100%',
                    display: 'flex',
                    paddingTop: '1%',
                    paddingBottom: '1%',
                    paddingLeft: '2.5%',
                    color: 'white',
                    fontWeight: 'bold',
                    backgroundColor: 'rgb(113,152,202)',

                }}>What is the meaning of all of this?
                </div>
                <div style={{paddingLeft: '5%',paddingRight: '5%', paddingBottom: '2.5%', paddingTop: '2.5%'}}>People have found meaning in a lot of things! Some people find it in their career or their family
                    or
                    their religion. Ultimately it is up to you to find meaning! You have the
                    freedom to dedicate your life to whatever matters most to you. Your burden is discovering what you
                    want
                    that
                    to be, followed by a tireless pursuit.
                </div>
            </div>
        </div>
    </div>
}

