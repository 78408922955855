import React, {Component} from 'react'
import {LoginContainer} from '../components/Login/LoginContainer';
import LogoContainer from './../components/Login/LeagueNameLogo';
import LoginForm from './../forms/LoginForm'

class LoginView extends Component {

    render() {
        return (
            <LoginContainer>
                <div style={{justifyContent: 'center', alignItems: 'center'}}>
                    {/*<LogoContainer />*/}
                    <div style={{
                        fontSize: 40,
                        color: 'white', textAlign: 'center'
                    }}>
                        MattSpace
                    </div>
                    <LoginForm/>
                </div>
            </LoginContainer>
        )
    }

}

export default LoginView
