import React, {useState} from 'react'
import {TopMenuLinkSeparator} from "./TopMenuLinkSeparator";
import {websiteUrl} from "../../service/_BackendUrls";

import {useNavigate} from "react-router-dom";
import './../MobileSearchBar/MobileSearchBar.css'

export const MobileSearchBar = (props) => {

    const [searchValue, setSearchValue] = useState('');

    const navigate = useNavigate();

    const routeChange = () => {
        navigate("/not_found", {state:{searchValue: searchValue}});
    }

    return <div className='mobileContainerTop'>
            <div className='mobileSearchBarContainer'>
                <input style={{width: '60%', height: '40px', fontSize: 16}} name="myInput" placeholder={'Search for Friends'} value={searchValue}
                       onChange={e => setSearchValue(e.target.value)} onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        routeChange();
                    }
                }
                }/>
                <button style={{marginLeft: '2%', height: '35px'}} onClick={() => routeChange()}>Search</button>
            </div>
        </div>
    }

