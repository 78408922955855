import React, {useState} from 'react'
import {TopMenuLinkSeparator} from "./TopMenuLinkSeparator";
import {FriendSpaceItem} from "./FriendSpace/FriendSpaceItem";
import AbbiePic from '../../img/Abbie.jpg';
import AnurupPic from './../../img/Anurup2.jpg';
import DickPic from './../../img/Dick.jpg'
import KylePic from './../../img/Kyle.jpg'
import TowniePic from './../../img/Townie.jpg'
import {FriendsCommentsItem} from "./FriendsComments/FriendsCommentsItem";
import {LatestBlogPostItem} from "./LatestBlogPosts/LatestBlogPostItem";


export const LatestBlogPosts = (props) => {

    const [viewAllBlogEntriesClicked, setViewAllBlogEntriesClicked] = useState(false);

    const ViewAllBlogEntriesButton = () => {
        if (viewAllBlogEntriesClicked === false) {
            return <div>
                [
                <button
                    style={{color: 'blue', background: 'none', border: 'none', padding: 0, cursor: 'pointer'}}
                    onClick={() => setViewAllBlogEntriesClicked(true)}
                >View All Blog Entries
                </button>
                ]
            </div>
        } else if (viewAllBlogEntriesClicked === true) {
            return <button
                style={{color: 'black', background: 'none', border: 'none', padding: 0}}
            >
                Displaying 4 of 4 Blog Entries [
                <button
                    style={{color: 'blue', background: 'none', border: 'none', padding: 0, cursor: 'pointer'}}
                    onClick={() => setViewAllBlogEntriesClicked(false)}
                >Show Less
                </button>
                ]
            </button>

        }
    }


    let ContainerStyle = {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'left',
        marginTop: '2.5%',
        marginBottom: '5%'
    }

    if(props.showNotFriendsExtendedNetworkMessage === true || props.showBlockedExtendedNetworkMessage === true){
        ContainerStyle = {display: 'none'}
    }

    return <div style={ContainerStyle}>
        <div style={{textAlign: 'left', paddingLeft: '5%', marginTop: '1%', fontWeight: 'bold'}}>Matt's Latest Blog
            Posts{' '}
        </div>
        <div style={{marginLeft: '2.5%', marginRight: '2.5%'}}>
            <LatestBlogPostItem title={'I sat outside and made a painting'} viewMoreLinkPathFromHome={'/painting'}/>
            <LatestBlogPostItem title={'I just released an app on the App Store!'}
                                viewMoreLinkPathFromHome={'/programming'}/>
            <LatestBlogPostItem title={'Learn to cook the best things I know'} viewMoreLinkPathFromHome={'/recipes'}/>
            <LatestBlogPostItem title={'A look back at some of my best photographs'}
                                viewMoreLinkPathFromHome={'/photography'}/>
        </div>
        <div style={{paddingLeft: '5%', marginTop: '2.5%'}}>
            <ViewAllBlogEntriesButton/>
        </div>
        {/*friend space item container*/}
    </div>
}

