import React from 'react';
import '../components/HomeView/Home.css'
import {TopMenuLinks} from "../components/HomeView/TopMenuLinks";
import {TopMenuLogo} from "../components/HomeView/TopMenuLogo";
import skullImage from "./../img/skull2.png"
import {MobileSearchBar} from "../components/HomeView/MobileSearchBar";
import './../components/PageNotFoundView/PageNotFoundView.css'
import {DetailsItem} from "../components/HomeView/Details/DetailsItem";
import mermaidPic from './../img/mermaid.png'

export const ScubaView = (props) => {

    return <div className='PageBackground'>
        <TopMenuLogo/>
        <MobileSearchBar/>
        <TopMenuLinks/>
        <div style={{textAlign: 'center', paddingTop: '5%'}}>
            <div style={{
                fontWeight: 'bold',
                backgroundColor: 'rgb(246,204,159)',
                color: 'rgb(201,90,35)',
                fontSize: 25,
                textAlign: 'left',
                paddingLeft: '5%'
            }}>Scuba F.A.Qs
            </div>
            <div style={{
                border: '2px solid rgb(48,93,150)',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'left',
                margin: '2.5%',
                marginTop: '2.5%',
            }}>
                <div style={{
                    textAlign: 'left',
                    flex: '0 0 100%',
                    display: 'flex',
                    paddingTop: '1%',
                    paddingBottom: '1%',
                    paddingLeft: '2.5%',
                    color: 'white',
                    fontWeight: 'bold',
                    backgroundColor: 'rgb(113,152,202)',

                }}>Why did you start scuba diving?
                </div>
                <div style={{paddingLeft: '5%', paddingRight: '5%', paddingBottom: '2.5%', paddingTop: '2.5%'}}>
                    When I was 14, a copy of the book Shadow Divers by Robert Kurson somehow found its way into my
                    possession. Inside
                    was a story about the discovery of a mysterious german submarine that sat sunken off the coast of
                    New Jersey. I was
                    inspired by this notion of adventure, and found a place in my landlocked state where I could get
                    certified.
                </div>
            </div>
            <div style={{
                border: '2px solid rgb(48,93,150)',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'left',
                margin: '2.5%',
                marginTop: '2.5%',
            }}>
                <div style={{
                    textAlign: 'left',
                    flex: '0 0 100%',
                    display: 'flex',
                    paddingTop: '1%',
                    paddingBottom: '1%',
                    paddingLeft: '2.5%',
                    color: 'white',
                    fontWeight: 'bold',
                    backgroundColor: 'rgb(113,152,202)',

                }}>How many dives do you have?
                </div>
                <div style={{paddingLeft: '5%', paddingRight: '5%', paddingBottom: '2.5%', paddingTop: '2.5%'}}>
                    I lost count while I was diving professionally, but I believe I'm somewhere in the 500's now.
                </div>
            </div>
            <div style={{
                border: '2px solid rgb(48,93,150)',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'left',
                margin: '2.5%',
                marginTop: '2.5%',
            }}>
                <div style={{
                    textAlign: 'left',
                    flex: '0 0 100%',
                    display: 'flex',
                    paddingTop: '1%',
                    paddingBottom: '1%',
                    paddingLeft: '2.5%',
                    color: 'white',
                    fontWeight: 'bold',
                    backgroundColor: 'rgb(113,152,202)',

                }}>What kind of certifications do you have?
                </div>
                <div style={{paddingLeft: '5%', paddingRight: '5%', paddingBottom: '2.5%', paddingTop: '2.5%'}}>
                    Currently I am a PADI Open Water Scuba Instructor, which allows me to teach Open Water, Advanced
                    Open Water, Rescue Diver, Divemaster, as well as first aid and CPR courses. Along the way I
                    naturally collected many speciality certifications including full face mask, self-reliant (solo)
                    diver, diver propulsion vehicle, dry suit, enriched air (Nitrox), and underwater hunter.
                </div>
            </div>
            <div style={{
                border: '2px solid rgb(48,93,150)',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'left',
                margin: '2.5%',
                marginTop: '2.5%',
            }}>
                <div style={{
                    textAlign: 'left',
                    flex: '0 0 100%',
                    display: 'flex',
                    paddingTop: '1%',
                    paddingBottom: '1%',
                    paddingLeft: '2.5%',
                    color: 'white',
                    fontWeight: 'bold',
                    backgroundColor: 'rgb(113,152,202)',

                }}>Have you ever tried to 69 a mermaid?
                </div>
                <div style={{
                    paddingLeft: '5%',
                    paddingRight: '5%',
                    paddingBottom: '2.5%',
                    paddingTop: '2.5%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '1%',
                    marginTop: '1%',
                    display: 'flex',
                    flex: 1,
                    width: '100%',
                    flexBasis: '200px',
                    maxWidth: '300px',
                }}>
                    <img src={mermaidPic} width={'100%'} style={{border: '2px solid rgb(48,93,150)', marginLeft: '10%'}}/>
                </div>
            </div>
        </div>
    </div>
}

