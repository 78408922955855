import React, {useState} from 'react';
import '../components/HomeView/Home.css'
import {TopMenuLinks} from "../components/HomeView/TopMenuLinks";
import {TopMenuLogo} from "../components/HomeView/TopMenuLogo";
import skullImage from "./../img/skull2.png"
import {MobileSearchBar} from "../components/HomeView/MobileSearchBar";
import './../components/PageNotFoundView/PageNotFoundView.css'
import {DetailsItem} from "../components/HomeView/Details/DetailsItem";
import {Route, useNavigate} from 'react-router-dom';
import './../components/RecipeListView/Recipe.css'

export const RecipeListView = (props) => {

    const navigate = useNavigate();

    const routeChange = (recipeObject) => {
        navigate("/recipe?" + recipeObject.title, {state: {recipeObject}});
    }

    const AvgolemenoRecipe = {
        title: 'Avgolemeno',
        subTitle: 'The best chicken soup you\'ve ever had',
        ingredients: [
            '2 cups finley chopped carrots',
            '2 cups finley chopped celery',
            '2 cups finley chopped green onions',
            '4 garlic cloves, finely chopped',
            '24 cups of chicken broth (6 x 32 oz Swanson Organic Chicken Broth)',
            '4 bay leaves',
            '2 cups orzo',
            '16 oz of chicken breast',
            '8 lemons (to create one cup of juice)',
            '4 large eggs',
            'Olive Oil',
            'Salt',
            'Pepper'
        ],
        steps: [
            'Hit the chicken with some salt a pepper, and throw it in the oven at 350 until it hits 160.  DO NOT OVERCOOK THE CHICKEN.  Let it cool, probably in the fridge because the rest of this will take a while.',
            'Very finley chop the green onions, carrots, celery, and garlic.  Doing this by hand will be quite time consuming, but I\'ve found using a food processor makes the end result look fit for a nursing home.  By finely chopping the vegetables you also end up with a soup that doesn\'t even really require chewing.  I suggest you put on some music, relax, and make an afternoon of it.',
            'In a very large pot, add some olive oil and the carrots, celery, and green onions.  Cook them until the carrots start getting some color, about 20 minutes.  Add the garlic and cook until it gets some color (garlic cooks much more quickly than the others).',
            'Add the chicken broth and bay leaves.  Bring to a boil, then reduce to medium heat and cook for as long as you can',
            'While the soup is going, shred all of your chicken breasts.  You can use two forks to help accomplish this.',
            'Add the orzo, cook until you can pull one out and it provides almost no resistant to the tooth.',
            'Add the chicken.',
            'Now the magic happens: we thicken the broth with a lemon-egg mixture.  Squeeze 1 cup of lemon juice, then add to bowl with eggs and whisk until smooth.  Now we are going to slowly raise the temperature of, or "temper" the mixture.  We don\'t want to end up with scrambled eggs, but as long as you\'re reasonably careful this isn\'t as hard as it sounds.  Let the soup cool from a boil first to be a little more careful.  Then, slowly add broth from the stove into your mixture while gently whisking.  Once you feel like you\'ve added enough broth to bring it up to around the same temperature as the rest of the soup, add the mixture to the pot'
        ]
    }

    const ManaqishRecipe = {
        title: 'Manakish',
        subTitle: 'Breakfast bread I could eat every single day',
        ingredients: [
            '1 pre-made flatbread (Stonefire 14.1 oz Flatbread)',
            'olive oil',
            'sesame seeds',
            'thyme',
            'sumac',
            'salt'
        ],
        steps: [
            'Throw that flatbread on a baking sheet.',
            'Cover with a healthy amount of olive oil.',
            'Absolutely cover flatbread with sesame seeds, thyme, sumac, and plenty of salt.  You could also purchase za\'atar seasoning if you want.',
            'Broil in oven until edges start to brown and/or olive oil starts to bubble',
            'Remove from oven, hit it with a pizza cutter, enjoy.'
           ]
    }

    const TomatoBisqueRecipe = {
        title: 'Tomato Bisque',
        subTitle: 'it\'s soup that is made from tomatoes!',
        ingredients: [
            '4 tablespoons butter',
            '1 yellow onion finely chopped',
            '1 carrot finely chopped',
            '1 celery rib finely chopped',
            '2 garlic cloves',
            '3 tablespoons flour',
            '4 cups chicken stock',
            '29 oz of tomatoes',
            '3 tablesppons tomato paste',
            '2 teaspoons brown sugar',
            '1/4 cup heavy cream',
            '1/4 cup balsamic vinegar',
            'Salt',
            'Pepper'
        ],
        steps: [
            'Slice tomatoes into a few pieces and add to baking sheet.  Slice top off garlic bulb and add to baking sheet.  Cover vegetables with olive oil and salt.',
            'Cook vegetables at 450 until they get some color.  You can use the broiler to help at the end.',
            'Blend tomatoes.  You can let them cool so you don\'t burn the hell out of yourself.',
            'In a pot, add 2 tablespoons of butter along with the onion, carrot and celery.  Cook these until they get some color.  Add balsamic vinegar and cook until slightly reduced.  Add garlic.',
            'Reduce heat.  Stir in flower until it is fully incorporated.',
            'Add chicken stock, tomatoes, tomato paste, brown sugar.  Bring to a boil and cook for as long as you want.',
            'Transfer soup to blender or use an immersion blender and blend until smooth',
            'Return to pan and add heavy cream'
        ]
    }

    return <div className='PageBackground'>
        <TopMenuLogo/>
        <MobileSearchBar/>
        <TopMenuLinks/>
        <div style={{textAlign: 'center'}}>
            {/*message*/}
            <div style={{backgroundColor: 'rgb(48,93,150)', width: '100%', padding: '1%'}}>
                <div style={{textAlign: 'center', fontSize: 18, color: 'white'}}>I've put together a list of recipes
                    that I think are exceptional. If you try one out and it's bad, it's probably on you.
                </div>
            </div>
            <ul style={{
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'left',
                margin: '2.5%',
                marginTop: '5%',
            }}>
                <li className='RecipeListItem' onClick={() => routeChange(AvgolemenoRecipe)}>Avgolemeno - the best chicken soup
                    you've ever had
                </li>
                <li className='RecipeListItem' onClick={() => routeChange(ManaqishRecipe)}>Manakish - breakfast bread I could eat every single day
                </li>
                <li className='RecipeListItem' onClick={() => routeChange(TomatoBisqueRecipe)}>Tomato Bisque - it's soup made from tomatoes!
                </li>
            </ul>
        </div>
    </div>
}

