import {
    backendUrl
} from './_BackendUrls';

class UserService {

    resetPassword(passwordResetToken, newPassword) {
        const passwordResetJSON = {
            token: passwordResetToken,
            password: newPassword,
        };

        return fetch(backendUrl + '/user/password/reset', {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'PUT',
            mode: 'cors',
            body: JSON.stringify(passwordResetJSON),
        });
    }

    requestPasswordResetEmail(email) {
        return fetch(backendUrl + '/user/password-reset-email/' + email, {
            headers: {
                Authorization: localStorage.getItem('accessToken'),
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        });
    }

}

export default new UserService();
