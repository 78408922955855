import React, {useState} from 'react';
import '../components/HomeView/Home.css'
import {TopMenuLinks} from "../components/HomeView/TopMenuLinks";
import {TopMenuLogo} from "../components/HomeView/TopMenuLogo";
import skullImage from "./../img/skull2.png"
import {MobileSearchBar} from "../components/HomeView/MobileSearchBar";
import './../components/PageNotFoundView/PageNotFoundView.css'
import {DetailsItem} from "../components/HomeView/Details/DetailsItem";
import {Route, useNavigate} from 'react-router-dom';
import './../components/RecipeListView/Recipe.css'
import {ExperienceItem} from "../components/ResumeView/ExperienceItem";
import deepRootsScreenshot from "../img/deepRoots2.jpg";

export const ResumeView = (props) => {

    const navigate = useNavigate();

    const routeChange = (recipeObject) => {
        navigate("/recipe?" + recipeObject.title, {state: {recipeObject}});
    }


    return <div className='PageBackground'>
        <TopMenuLogo/>
        <MobileSearchBar/>
        <TopMenuLinks/>
        <div style={{
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            backgroundColor: '#a8a7a7'
        }}>
            <div style={{
                backgroundColor: 'white',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '.5%',
                flex: 1,
                width: '100%',
                flexBasis: '600px',
                maxWidth: '900px',
                marginBottom: '2.5%',
                alignSelf: 'stretch',
                marginTop: '2.5%',
                filter: 'drop-shadow(-1mm 1mm 1mm rgba(25,25,25, .5))'
            }}>
                <div style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'left',
                    margin: '2.5%',
                    marginTop: '5%',
                }}>
                    <div style={{fontSize: 25}}>Matt Freedman</div>
                    <div style={{fontSize: 16}}>Programmer - Web Developer - Scuba Instructor - Artist</div>
                    <div style={{fontSize: 16}}>San Diego, CA</div>
                </div>
                {/*Jobs*/}
                <div style={{fontSize: 22, textAlign: 'left', marginLeft: '2.5%'}}>Experience</div>
                <div style={{paddingLeft: '2.5%'}}>
                    <ExperienceItem name={'Pro-Social'}
                                    location={'San Diego - Denver - Bloomington'}
                                    duration={'February 2021 - Present'}
                                    role={'Founder'}
                                    responsibilities={['React/React Native/Spring Boot/PostgresQL/RabbitMQ set of applications designed to help connect people with common interests. Contains WebSocket and REST components documented using Swagger. Fully designed, implemented, and deployed by me to Elastic Beanstalk, Amplify, RDS, and AmazonMQ.',
                                        'The iOS App provides a safe environment to create meaningful connections with new people online.']}/>

                    <ExperienceItem name={'The LEAGUE of Amazing Programmers'}
                                    location={'San Diego County'}
                                    duration={'June 2017 - September 2022'}
                                    role={'Lead Teacher - Software Developer'}
                                    responsibilities={['Developed business-critical infrastructure required for day-to-day operations. Creator and maintainer of higher-level curriculum for students, which covered developing applications using Spring, as well as unit testing and integration testing.',
                                        'Taught students from 5th to 12th grade Java programming skills through interactive programs and classic games. Students start by learning the fundamentals of the Java language, and progress through creating their own games, AP exam topics, earning their Oracle Certified Java Programmer certification, and finish by developing a production-quality Spring Boot application.',
                                        'Led a team of high school students in creating a website for a local non-profit organization.']}/>
                    <ExperienceItem name={'Living Ocean Scuba'}
                                    location={'Honolulu'}
                                    duration={'January 2016 - January 2016'}
                                    role={'Padi Open Water Scuba Instructor'}
                                    responsibilities={['Lead Discover Scuba Diving trips with brand-new divers by boat from Kewalo Basin and from land at Hanauma Bay.']}/>
                    <ExperienceItem name={'Island Divers Hawaii'}
                                    location={'Honolulu'}
                                    duration={'January 2015 - January 2016'}
                                    role={'Web Developer'}
                                    responsibilities={['Created a website to display information on the business. Incorporated an online booking service into the website that allowed customers to book dives online.']}/>
                </div>
                {/*Education*/}
                <div style={{
                    fontSize: 22,
                    textAlign: 'left',
                    marginLeft: '2.5%',
                    marginTop: '2.5%'
                }}>Education
                </div>
                <div style={{paddingLeft: '2.5%'}}>
                    <ExperienceItem name={'Illinois Wesleyan University'}
                                    location={'Bloomington, IL'}
                                    duration={'January 2009 - January 2014'}
                                    role={'Bachelor\'s degree, Computer Science'}
                                    responsibilities={[]}/>
                </div>
                {/*Certifications*/}
                <div style={{
                    fontSize: 22,
                    textAlign: 'left',
                    marginLeft: '2.5%',
                    marginTop: '2.5%'
                }}>Certifications
                </div>
                <div style={{paddingLeft: '2.5%', marginBottom: '7.5%'}}>
                    <ExperienceItem name={'PADI Open Water Scuba Instructor'}
                                    duration={'December 2015 - Present'}
                                    role={''}
                                    responsibilities={[]}/>
                    <ExperienceItem name={'Oracle Certified Associate, Java SE 8 Programmer'}
                                    duration={'January 2017 - Present'}
                                    role={''}
                                    responsibilities={[]}/>


                </div>
            </div>
        </div>
    </div>
}

