import React from 'react'
import './FriendSpace.css'
export const FriendSpaceItem = (props) => {

    return <button className='FriendSpaceItemContainer'>
        <div style={{
            flexDirection: 'column',
            display: 'flex'
        }}>
            <div style={{color:'rgb(48,93,150)', fontWeight: 'bold'}}>{props.name}</div>
            <img src={props.picture} width={'100%'} style={{ border: '2px solid rgb(48,93,150)'}}/>
        </div>
    </button>
}

