import React from 'react'

export const PortraitsItem = (props) => {

    return <div style={{
        // alignItems: 'center',
        // justifyContent: 'center',
        // padding: '1%',
        // marginTop: '1%',
        display: 'flex',
        flex: 1,
        // width: '100%',
        flexBasis: '200px',
        maxWidth: '800px',
        // width: '30%',
        // height: '100%',

    }}>
        <div style={{
            // flexDirection: 'column',
            // display: 'flex',
            flex: 1,
            width: '100%',
            padding: '5%',
        }}>
            <div style={{color: 'rgb(48,93,150)', fontWeight: 'bold'}}>{props.name}</div>
            <img src={props.picture} width={'100%'} style={{border: '2px solid black', height: '20vh', objectFit: 'scale-down', backgroundColor: 'rgb(25,33,35)'
                }}/>
        </div>
    </div>
}

