import React from 'react'

export const ContactItem = (props) => {

    return <button style={{
        alignItems: 'center',
        textAlign: 'left',
        padding: '1%',
        marginTop: '2%',
        width: '40%',
        background: 'none',
        border: 'none',
        flexDirection: 'row',
        display: 'flex',
        cursor: 'pointer'
    }}
                   onClick={props.onClick}>
        <img src={props.icon} style={{width: 12}}/>
        <div style={{paddingLeft: '5%'}}>{props.description}</div>
    </button>
}

