import React from 'react'
import {ContactItem} from "./ContactingMe/ContactItem";
import emailIcon from "./../../img/icons/email.png";
import crossedOutIcon from "./../../img/icons/crossedOutIcon.png";
import heartIcon from "./../../img/icons/heartIcon.png";
import heartIconFilled from "./../../img/icons/heartIconFilled.png";
import speechIcon from "./../../img/icons/speechBubble.png";
import coffeeIcon from "./../../img/icons/coffee.png";
import xIcon from "./../../img/icons/x.png";
import plusIcon from "./../../img/icons/plus3.png"
import {useNavigate} from "react-router-dom";

export const ContactingMe = (props) => {

    const navigate = useNavigate();

    const routeChangeToMessenger = () => {
        navigate("/instant-messenger");
    }

    let ContainerStyle = {
        border: '2px solid rgb(48,93,150)',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'left',
        margin: '2.5%',
        marginTop: 0,
    }

    if (props.showNotFriendsExtendedNetworkMessage === true || props.showBlockedExtendedNetworkMessage === true) {
        ContainerStyle = {
            border: '2px solid rgb(48,93,150)',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'left',
            margin: '2.5%',
            marginTop: '5%',
        }
    }

    const FriendContactItem = () => {
        if (props.showNotFriendsExtendedNetworkMessage === true) {
            return <ContactItem description={'Add Friend'} icon={plusIcon}
                                onClick={() => props.setShowNotFriendsExtendedNetworkMessage(false)}/>
        } else {
            return <ContactItem description={'Remove from Friends'} icon={xIcon}
                                onClick={() => {
                                    props.setShowNotFriendsExtendedNetworkMessage(true);
                                    props.setShowFavoritedExtendedNetworkMessage(false);
                                }}/>
        }

    }

    const FavoritedContactItem = () => {
        if (props.showFavoritedExtendedNetworkMessage === true) {
            return <ContactItem description={'Remove from Favorites'} icon={heartIconFilled}
                                onClick={() => {
                                    if (props.showNotFriendsExtendedNetworkMessage === false && props.showBlockedExtendedNetworkMessage === false) {
                                        props.setShowFavoritedExtendedNetworkMessage(false)
                                    }
                                }}/>
        } else {
            return <ContactItem description={'Add to Favorites'} icon={heartIcon}
                                onClick={() => {
                                    if (props.showNotFriendsExtendedNetworkMessage === false && props.showBlockedExtendedNetworkMessage === false) {
                                        props.setShowFavoritedExtendedNetworkMessage(true);
                                        props.startHeartDrop();
                                    }
                                }}/>
        }

    }

    const BlockedContactItem = () => {
        if (props.showBlockedExtendedNetworkMessage === true) {
            return <ContactItem description={'Unblock User'} icon={crossedOutIcon}
                                onClick={() => props.setShowBlockedExtendedNetworkMessage(false)}/>
        } else {
            return <ContactItem description={'Block User'} icon={crossedOutIcon}
                                onClick={() => {
                                    props.setShowBlockedExtendedNetworkMessage(true);
                                    props.setShowFavoritedExtendedNetworkMessage(false);
                                }}/>
        }

    }


    return <div style={ContainerStyle}>
        <div style={{
            display: 'flex',
            flexFlow: 'row wrap', alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '2%'
        }}>
            <div style={{
                textAlign: 'left',
                flex: '0 0 100%',
                display: 'flex',
                paddingTop: '1%',
                paddingBottom: '1%',
                paddingLeft: '2.5%',
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: 'rgb(113,152,202)',
            }}>Contacting Matt
            </div>
            <ContactItem description={'Send Message'} icon={emailIcon} onClick={
                () => window.location = 'mailto:mjfreDeveloper@gmail.com?subject=Found Your Website'}/>
            <ContactItem description={'Forward to a Friend'} icon={coffeeIcon} onClick={
                () => window.location = 'mailto:YOUR FRIEND?subject=Check out this guy&body=he\'s made himself a website that looks like a myspace page.'
            }/>
            <FriendContactItem/>
            <FavoritedContactItem/>
            <ContactItem description={'Instant Message'} icon={speechIcon}
                         onClick={() => routeChangeToMessenger()}/>
            <BlockedContactItem/>
        </div>
    </div>
}

