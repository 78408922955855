import React from 'react';
import '../components/HomeView/Home.css'
import {TopMenuLinks} from "../components/HomeView/TopMenuLinks";
import {TopMenuLogo} from "../components/HomeView/TopMenuLogo";
import {PaintingItem} from "../components/PaintingView/PaitingItem";
import ObLifeguardStation1 from './../img/paintings/IMG_5297.jpg'
import pic2 from './../img/paintings/IMG_4800.jpg'
import pic3 from './../img/paintings/IMG_5246.jpg'
import pic4 from './../img/paintings/IMG_5263.jpg'
import pic5 from './../img/paintings/IMG_5286.jpg'

import pic6 from './../img/paintings/IMG_1.jpg'
import pic7 from './../img/paintings/IMG_2.jpg'
import pic8 from './../img/paintings/IMG_3.jpg'
import pic9 from './../img/paintings/IMG_4.jpg'
import pic10 from './../img/paintings/IMG_5.jpg'
import pic11 from './../img/paintings/IMG_6.jpg'
import pic12 from './../img/paintings/IMG_7.jpg'
import pic13 from './../img/paintings/IMG_8.jpg'
import pic14 from './../img/paintings/IMG_9.jpg'
import pic15 from './../img/paintings/IMG_10.jpg'
import pic16 from './../img/paintings/IMG_11.jpg'
import pic17 from './../img/paintings/IMG_12.jpg'
import {MobileSearchBar} from "../components/HomeView/MobileSearchBar";


export const PaintingView = () => {

    return <div className='PageBackground'>
        <TopMenuLogo/>
        <MobileSearchBar/>
        <TopMenuLinks/>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            {/*message*/}
            <div style={{backgroundColor: 'rgb(48,93,150)', width: '100%', padding: '1%'}}>
                <div style={{textAlign: 'center', fontSize: 18, color: 'white'}}>Sometimes I like to go sit outside an paint things. I
                only started about a year ago. Typically I use some combination of watercolors, Sakura Micron fineliner pens, and fountain
                pens.
                </div>
            </div>
            <PaintingItem name={'Lifeguard Station Ocean Beach'} picture={ObLifeguardStation1}/>
            <PaintingItem name={'Downtown San Diego'} picture={pic5}/>
            <PaintingItem name={'Ocean Beach Pier'} picture={pic4}/>
            <PaintingItem name={'Ocean Beach Pier'} picture={pic3}/>
            <PaintingItem name={'Willow Springs Music Festival'} picture={pic2}/>

            <PaintingItem name={'Diesel Dick\'s'} picture={pic17}/>
            <PaintingItem name={'Indian River Inlet'} picture={pic16}/>
            <PaintingItem name={'Point Loma'} picture={pic15}/>
            <PaintingItem name={'Ocean Beach'} picture={pic14}/>
            <PaintingItem name={'San Diego Bay'} picture={pic13}/>
            <PaintingItem name={'Sunset Cliffs Sea Cave'} picture={pic12}/>
            <PaintingItem name={'Ocean Beach Farmers Market'} picture={pic11}/>
            <PaintingItem name={'Sunset Cliffs'} picture={pic10}/>
            <PaintingItem name={'Hotel del Coronado'} picture={pic9}/>
            <PaintingItem name={'Ocean Beach'} picture={pic8}/>
            <PaintingItem name={'Ocean Beach'} picture={pic7}/>
            <PaintingItem name={'Ocean Beach'} picture={pic6}/>


        </div>
    </div>
}

