import React from 'react'
import {TopMenuLinkSeparator} from "./TopMenuLinkSeparator";
import './ExtendedNetworkMessage/ExtendedNetworkMessage.css'

export const ExtendedNetworkMessage = (props) => {

    if (props.showBlockedExtendedNetworkMessage === true){
        return <div className='extendedNetworkMessage'>
            You have blocked this user.  You must unblock this user to see his/her profile.
        </div>
    } else if(props.showNotFriendsExtendedNetworkMessage === true){
        return <div className='extendedNetworkMessage'>
            This profile is set to private.  You must add this user as a friend to see his/her profile.
        </div>
    } else if (props.showFavoritedExtendedNetworkMessage === true){
        return <div className='extendedNetworkMessage'>
            Matt is in your favorites!
        </div>
    }  else {
        return <div className='extendedNetworkMessage'>
            Matt is in your extended network
        </div>
    }

}

