import React from 'react';
import '../components/HomeView/Home.css'
import {TopMenuLinks} from "../components/HomeView/TopMenuLinks";
import {TopMenuLogo} from "../components/HomeView/TopMenuLogo";
import skullImage from "./../img/skull2.png"
import {MobileSearchBar} from "../components/HomeView/MobileSearchBar";
import './../components/PageNotFoundView/PageNotFoundView.css'
import {useLocation} from "react-router-dom";

export const RecipeView = (props) => {

    const {state} = useLocation();
    return <div className='PageBackground'>
        <TopMenuLogo/>
        <MobileSearchBar/>
        <TopMenuLinks/>
        <div style={{paddingTop: '5%', marginLeft: '5%', marginRight: '5%'}}>
            <div style={{marginBottom: '2.5%'}}>
                <div style={{fontSize: 25,   backgroundColor: 'rgb(246,204,159)',
                    color: 'rgb(201,90,35)', paddingLeft: '2.5%'}}>{state.recipeObject.title}</div>
                <div style={{color: 'black', fontSize: 20, paddingLeft: '2.5%'}}>{state.recipeObject.subTitle}</div>
            </div>
            <div style={{fontSize: 20,   backgroundColor: 'rgb(246,204,159)',
                color: 'rgb(201,90,35)', paddingLeft: '2.5%', marginBottom: '1%'}}>Ingredients</div>
            <ul>
            {state.recipeObject.ingredients.map((ingredient,i2) => {
                console.log('test');
                return <li key={ingredient}>{ingredient}</li>
            })
            }
            </ul>
            <div style={{fontSize: 20,   backgroundColor: 'rgb(246,204,159)',
                color: 'rgb(201,90,35)', paddingLeft: '2.5%', marginBottom: '1%'}}>Steps:</div>
            <ol style={{marginBottom: '5%'}}>
                {state.recipeObject.steps.map((step,i2) => {
                    return <li key={step}>{step}</li>
                })
                }
            </ol>
        </div>
    </div>
}

