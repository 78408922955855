import React from 'react'

export const ExperienceItem = (props) => {

    return <div style={{
        paddingTop: '.5%',
        paddingBottom: '.5%',
        display: 'flex',
        flex: 1,
        width: '100%',
        flexBasis: '600px',
        maxWidth: '900px',
        marginBottom: 'auto',
        alignSelf: 'stretch',
        paddingLeft: '1.5%',

    }}>
        <div style={{
            flexDirection: 'column',
            display: 'flex',
            flex: 1,
            width: '100%',
            padding: '2.5%',
            border: '2px solid rgb(48,93,150)',
            marginRight: '2.5%'
        }}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <div style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'left',
                    textAlign: 'left'
                }}>
                    <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                        <div style={{
                            fontWeight: 'bold',
                            backgroundColor: 'rgb(246,204,159)',
                            color: 'rgb(201,90,35)',
                            paddingLeft: '5%',
                            width: '50%',
                            fontSize: 20,
                            height: '100%',
                            alignItems: 'center',
                            display: 'flex'
                        }}>{props.name}</div>
                        <div style={{display: 'flex', flexDirection: 'column', width: '50%', height: '100%'}}>
                            <div style={{
                                backgroundColor: 'rgb(246,204,159)',
                                color: 'rgb(201,90,35)',
                                paddingLeft: '5%',
                                width: '100%',
                                textAlign: 'right',
                                paddingRight: '2.5%'
                            }}>{props.duration}</div>
                            <div style={{
                                backgroundColor: 'rgb(246,204,159)',
                                color: 'rgb(201,90,35)',
                                paddingLeft: '5%',
                                width: '100%',
                                textAlign: 'right',
                                paddingRight: '2.5%'
                            }}>{props.location}</div>
                        </div>
                    </div>
                    <div style={{
                        paddingBottom: '2.5%',
                        paddingLeft: '5%',
                        color: "rgb(48,93,150)",
                        fontWeight: 'bold',
                        fontSize: 16
                    }}>{props.role}</div>
                    <ul style={{marginLeft: '3%', marginRight: '3%'}}>
                        {props.responsibilities.map(function (d, idx) {
                            return (<li key={idx}>{d}</li>)
                        })}
                    </ul>
                </div>
            </div>

            <div>{props.description}</div>
        </div>
    </div>
}

