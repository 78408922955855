import React from 'react';
import '../components/HomeView/Home.css'
import {TopMenuLinks} from "../components/HomeView/TopMenuLinks";
import {TopMenuLogo} from "../components/HomeView/TopMenuLogo";
import skullImage from "./../img/skull2.png"
import {MobileSearchBar} from "../components/HomeView/MobileSearchBar";
import './../components/PageNotFoundView/PageNotFoundView.css'
import {DetailsItem} from "../components/HomeView/Details/DetailsItem";
import {PortraitsItem} from "../components/PortraitsView/PortraitsItem";

import pic1 from './../img/portraits/1.png'
import pic2 from './../img/portraits/2.png'
import pic3 from './../img/portraits/3.png'
import pic4 from './../img/portraits/4.png'
import pic5 from './../img/portraits/5.png'
import pic6 from './../img/portraits/6.png'
import pic7 from './../img/portraits/7.png'
import pic8 from './../img/portraits/8.png'
import pic9 from './../img/portraits/9.png'
import pic10 from './../img/portraits/10.png'

import pic11 from './../img/portraits/11.png'
import pic12 from './../img/portraits/12.png'
import pic13 from './../img/portraits/13.png'
import pic14 from './../img/portraits/14.png'
import pic15 from './../img/portraits/15.png'
import pic16 from './../img/portraits/16.png'
import pic17 from './../img/portraits/17.png'
import pic18 from './../img/portraits/18.png'
import pic19 from './../img/portraits/19.png'
import pic20 from './../img/portraits/20.png'

import pic21 from './../img/portraits/21.png'
import pic22 from './../img/portraits/22.png'
import pic23 from './../img/portraits/23.png'
import pic24 from './../img/portraits/24.png'
import pic25 from './../img/portraits/25.png'
import pic26 from './../img/portraits/26.png'
import pic27 from './../img/portraits/27.png'
import pic28 from './../img/portraits/28.png'
import pic29 from './../img/portraits/29.png'
import pic30 from './../img/portraits/30.png'

import pic31 from './../img/portraits/31.png'
import pic32 from './../img/portraits/32.png'
import pic33 from './../img/portraits/33.png'
import pic34 from './../img/portraits/34.png'
import pic35 from './../img/portraits/35.png'
import {useHistory, useNavigate} from "react-router-dom";

export const VideosView = (props) => {

    const routeChange = () => {
        navigate("/");
    }

    const navigate = useNavigate();

    return <div className='PageBackground'>
        <TopMenuLogo/>
        <MobileSearchBar/>
        <TopMenuLinks/>
        <div style={{textAlign: 'center'}}>
            <div className='PortraitViewBodyContainer'>
                <div style={{
                    display: 'flex',
                    paddingTop: '1%',
                    paddingBottom: '1%',
                    paddingLeft: '2.5%',
                    color: 'white',
                    fontWeight: 'bold',
                    backgroundColor: 'rgb(113,152,202)',

                }}>
                    <button
                        onClick={() => {routeChange()}}
                        type="primary"
                        className='PortraitViewBackButton'
                    >
                        &lt; Back
                    </button>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                    Matt's Videos
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    flexWrap: 'wrap',
                }}>
                    <div style={{padding: '5%', paddingTop: '2.5%'}}>
                    Matt has not uploaded any videos
                    </div>
                </div>
            </div>
        </div>
    </div>
}

