import React from 'react'
import {websiteUrl} from "../../../service/_BackendUrls";

export const LatestBlogPostItem = (props) => {

    return <div style={{
        width: '100%',
        background: 'none',
        display: 'flex',
        marginLeft: '2.5%',
        marginTop: '2.5%',
    }}>
        <div style={{
            color: 'rgb(48,93,150)',
        }}>{props.title}</div>
        <div style={{}}>{props.value}</div>
        <div style={{paddingLeft: '1%', flexDirection: 'row', display: 'flex', textAlign: 'left'}}>
            <div>(</div>
            <a href={websiteUrl + props.viewMoreLinkPathFromHome} >view more</a>
            <div>)</div>
        </div>
    </div>
}

