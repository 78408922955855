import React from 'react'
import {ContactItem} from "./ContactingMe/ContactItem";

export const MattSpaceUrl = (props) => {

    return <div style={{
        border: '2px solid rgb(48,93,150)',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'left',
        margin: '2.5%',
        marginTop: '5%',
        marinBottom: '5%'
    }}>
        <div style={{
            textAlign: 'left',
            flex: '0 0 100%',
            display: 'flex',
            paddingTop: '1%',
            paddingBottom: '1%',
            paddingLeft: '2.5%',
        }}>
            <div style={{fontWeight: 'bold'}}>MattSpace URL:</div>
            <a href={'https://www.mjfre.com'} target='_blank'
               style={{
                   marginLeft: '1%'
               }}>https://www.mjfre.com</a>
        </div>
    </div>
}

