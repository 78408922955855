import React from 'react'
import './MobileExtendedNetworkMessage.css';

export const MobileExtendedNetworkMessage = (props) => {

    if(props.showNotFriendsExtendedNetworkMessage === true){
        return <div className='mobileExtendedNetworkMessage'>
            This profile is set to private.  You must add this user as a friend to see his/her profile.
        </div>
    } else if (props.showFavoritedExtendedNetworkMessage === true){
        return <div className='mobileExtendedNetworkMessage'>
            Matt is in your favorites!
        </div>
    } else if (props.showBlockedExtendedNetworkMessage === true){
        return <div className='mobileExtendedNetworkMessage'>
            You have blocked this user.  You must unblock this user to see his/her profile.
        </div>
    } else {
        return <div className='mobileExtendedNetworkMessage'>
            Matt is in your extended network
        </div>
    }

}

