import React from 'react';
import '../components/HomeView/Home.css'
import {TopMenuLinks} from "../components/HomeView/TopMenuLinks";
import {TopMenuLogo} from "../components/HomeView/TopMenuLogo";
import proSocialIcon from "../img/pro-socialIcon.jpg";
import deepRootsScreenshot from "../img/deepRoots2.jpg"
import mattspaceScreenshot from "../img/mattspace.jpg"
import theLeagueScreenshot from "../img/theLeague.jpg"
import leagueLevel7Screenshot from "../img/leagueLevel7.jpg"
import hawaiiScreenshot from "./../img/hawaii.jpg"
import delMarScreenshot from "./../img/delMarGardenClub.jpg"
import codeScreenshot from "./../img/code.jpg"
import cheetahScreenshot from "./../img/cheetah.jpg"
import githubScreenshot from "./../img/github.jpg"

import {ProgrammingViewItem} from "../components/ProgrammingView/ProgrammingViewItem";
import {MobileSearchBar} from "../components/HomeView/MobileSearchBar";
import './../components/MobileSearchBar/MobileSearchBar.css'

export const ProgrammingView = () => {

    return <div className='PageBackground'>
        <TopMenuLogo/>
        <MobileSearchBar/>
        <TopMenuLinks/>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            {/*message*/}
            <div style={{backgroundColor: 'rgb(48,93,150)', width: '100%', padding: '1%'}}>
                <div style={{textAlign: 'center', fontSize: 18, color: 'white'}}>I'm pretty good at convincing computers
                    to do what I'd like them to. I enjoy designing and building webpages, and my specialty is developing
                    Spring applications in the Representation State Transfer style.
                </div>
            </div>
            <div style={{display: 'flex',
                flexDirection: 'row',
                height: '100%',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'stretch',}}>
            <ProgrammingViewItem name={'Deep Roots Tree Care Website'}
                                 link={'https://www.DeepRootsTreeCare.com'}
                                 picture={deepRootsScreenshot}
                                 description={'Deep Roots Tree Care is operated in beautiful Central Illinois.  I created a website to inform potential customers that the business exists. The site displays the services offered, some background information about how the business operates, as well as provides some social proof that they do, in fact, cut down trees.'}
                                 technologies={['React', 'AWS Amplify']}/>
            <ProgrammingViewItem name={'Pro-Social (iOS App)'}
                                 link={'https://apps.apple.com/us/app/pro-social/id1622260145'}
                                 picture={proSocialIcon}
                                 description={'Pro-Social is designed to introduce people with similar interests.  Conceived during the pandemic, it was 100% designed, developed, and deployed by me and is now available on the Apple App Store.  It is a complex group of applications including the app that is actually run on the users phone. In support of that is the Spring Boot application that handles both updating user data (through RESTful API requests) as well as connecting users in real time to each other (through a websocket connection). This could be thought of as the real "brains" of the operation, without the Spring application, the app itself would be unable to do anything.  There is also a database that is used to store all of the users information, and a peculiar instance of an application known as a "message broker", that holds onto any messages sent between users until the user connects and is able to actually receive it.'}
                                 technologies={['React Native', 'Spring Boot', 'PostgreSQL', 'RabbitMQ', 'Docker', 'AWS Elastic Beanstalk', 'AWS Relational Database Service', 'AWS Elastic Compute Cloud', 'AWS Simple Email Service',]}/>
            <ProgrammingViewItem name={'This Website'}
                                 link={'https://www.mjfre.com'}
                                 picture={mattspaceScreenshot}
                                 description={'I made this website to display my stuff. I don\'t really do social media, so this is a place where I can collect things. I built it from scratch using react, in the style of an original MySpace page.  Plus, I figured a web developer without a website is like a mechanic without a car.'}
                                 technologies={['React', 'AWS Amplify']}/>
            <ProgrammingViewItem name={'Staff and Student Portal'}
                                 link={'https://my.jointheleague.org'}
                                 picture={theLeagueScreenshot}
                                 description={'This project was in active development for a couple of years, and eventually provided teachers, administrators, and students with everything they needed to conduct classes at The League of Amazing Programmers. The functionality ranged from viewing information about classes and the students within them, to updating student records when they graduated a level.  There was a full assessment test that could be provided to new students or teachers, which asked questions from every level of the curriculum until the user missed more than 3 questions within a level.  There was also a massive amount of data collection and visualization, including data on things like student numbers, enrollment trends over time, and which level of the curriculum students dropped out at. We had all the code to allow all of the students to more easily access their information ready to begin testing when development of the project was forced to abruptly cease.'}
                                 technologies={['React', 'Spring Boot', 'PostgreSQL', "Jira", "AWS Elastic Beanstalk", "AWS Relational Database Management System", "AWS Elastic Compute Cloud", 'AWS Amplify',]}/>
            <ProgrammingViewItem name={'Building RESTful APIs with Spring Boot (Course)'}
                                 link={'https://level7.jointheleague.org/walkthrough/projectOverview.html'}
                                 picture={leagueLevel7Screenshot}
                                 description={'I developed this course for The League of Amazing Programmers, as new curriculum for their high-level students.  It discusses building a REST API with Spring Boot, from initializing the project all the way through unit and integration testing. It is extremely thorough, and while it likely hasn\'t been updated in a while, was far more concise than anything else available online.'}
                                 technologies={['Spring Boot', 'Gradle', 'Unit Testing', 'Integration Testing', 'React']}/>

            <ProgrammingViewItem name={'Cheetah Search'}
                                 link={'https://cheetah.jointheleague.org'}
                                 picture={cheetahScreenshot}
                                 description={'I guided a group of students as they took my Spring Boot application curriculum for its inaugural trip.  Their project allows you to search by books based on a keyword, by having the Spring Boot API that the website communicates to interface with an external API that contains information about books.  There were many other classes I led which completed similar sets of applications, with some returning information about recipes and even information on arriving and departing flights.'}
                                 technologies={['Spring Boot', 'Gradle', 'Unit Testing', 'Integration Testing', 'React']}/>


            <ProgrammingViewItem name={'Crypto Wallet Project'}
                                 link={'https://my.jointheleague.org'}
                                 picture={codeScreenshot}
                                 description={'The core of this project is a Spring Boot API that stores and distributes Thor wallet address.  The user provides a Terra wallet address, and the application arbitrarily assigns and records an unused Thor wallet address. I can\'t remember specifically why they wanted to accomplish this, but it\'s what I was asked to make for them!'}
                                 technologies={['React', 'Spring Boot', 'PostgreSQL', "AWS Elastic Beanstalk", "AWS Relational Database Management System", "AWS Amplify"]}/>


            <ProgrammingViewItem name={'PACER App'}
                                 link={'none'}
                                 picture={githubScreenshot}
                                 description={'This was a Selenium application designed to facilitate bulk downloading of court documents from the Public Access to Court Electronic Records system.  Apparently the issue was that users were unable to download all of the files for a case, and it was very time consuming to download each of them individually.  Was what we were doing legal?  I can only assume so since we were asked to do it by a lawyer.'}
                                 technologies={['Java', 'Selenium']}/>





            <ProgrammingViewItem name={'Del Mar Garden Club Website'}
                                 link={'defunct'}
                                 picture={delMarScreenshot}
                                 description={'I taught a couple teenagers how to make this website from step one, including meeting with the client to understand and manage their needs.  They did a fantastic job and I was quite proud of their work, but the site has since been replaced.'}
                                 technologies={['WordPress']}/>
            <ProgrammingViewItem name={'Discover Scuba Diving in Hawaii Website'}
                                 link={'defunct'}
                                 picture={hawaiiScreenshot}
                                 description={'I made this website while I lived in Hawaii.  I was tasked with creating a website and integrating online booking using FareHarbor. I worked with the founders of FareHarbor while I was there, and one of my only regrets in life is not jumping ship when I was asked if I would be willing to make websites for them.'}
                                 technologies={['WordPress', 'FareHarbor']}/>
            </div>

        </div>
    </div>
}

