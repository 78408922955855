import React from 'react'

export const ProgrammingViewItem = (props) => {

    return <div style={{
        padding: '1%',
        marginTop: '1%',
        display: 'flex',
        flex: 1,
        width: '100%',
        flexBasis: '600px',
        maxWidth: '800px',
        marginBottom: 'auto',
        alignSelf: 'stretch',

    }}>
        <div style={{
            flexDirection: 'column',
            display: 'flex',
            flex: 1,
            width: '100%',
            padding: '2.5%',
            border: '2px solid rgb(48,93,150)',
        }}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <div style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'left', textAlign: 'left'}}>
                    <div style={{fontWeight: 'bold',backgroundColor: 'rgb(246,204,159)', color: 'rgb(201,90,35)', paddingLeft: '5%'}}>{props.name}</div>

                    {props.link !== 'none' && props.link !== 'defunct' ?
                        <a href={props.link} target="_blank" rel="noreferrer" style={{paddingBottom: '5%', paddingLeft: '5%'}}>{props.link}</a>
                        : <div style={{paddingBottom: '5%', paddingLeft: '5%'}}>{props.link}</div>}
                    <div style={{paddingTop: 0, paddingLeft: '5%'}}>Technologies:</div>
                    <ul>
                        {props.technologies.map(function (d, idx) {
                            return (<li key={idx}>{d}</li>)
                        })}
                    </ul>
                </div>
                <div style={{display: 'flex', padding: '5%', paddingTop: 0, alignItems: 'center', justifyContent: 'center'}}>
                    <img src={props.picture} width={'50%'} style={{
                        display: 'flex',
                        flex: 1,
                        border: '2px solid rgb(48,93,150)',
                        filter: 'drop-shadow(1mm 1mm 1mm rgb(25,25,25))',
                        maxHeight: '200px',
                        flexBasis: '500px',
                    }}/>
                </div>
            </div>

            <div>{props.description}</div>
        </div>
    </div>
}

