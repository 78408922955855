import React from 'react'
import {TopMenuLinkSeparator} from "./TopMenuLinkSeparator";
import {websiteUrl} from "../../service/_BackendUrls";

export const TopMenuLinks = (props) => {

    return <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        backgroundColor: 'rgb(113,152,202)',
        textAlign: 'center',
        paddingTop: '.5%',
        paddingBottom: '.5%',
        justifyContent: 'center',
        paddingLeft: '5%',
        paddingRight: '5%'
    }}>
        <a href={websiteUrl} className="TopMenuLink">Home</a>
        <TopMenuLinkSeparator/>
        <a href={websiteUrl + '/painting'} className="TopMenuLink">Paintings</a>
        <TopMenuLinkSeparator/>
        <a href={websiteUrl + '/programming'} className="TopMenuLink">Programming</a>
        <TopMenuLinkSeparator/>
        <a href={websiteUrl + '/recipes'}
           className="TopMenuLink">Recipes</a>
        <TopMenuLinkSeparator/>
        <a href={websiteUrl + '/scuba'}
           className="TopMenuLink">Scuba</a>
        <TopMenuLinkSeparator/>
        <a href={websiteUrl + '/photography'}
           className="TopMenuLink">Photography</a>
        <TopMenuLinkSeparator/>
        <a href={'https://pro-social.mjfre.com'} target='_blank' className="TopMenuLink">Pro-Social</a>
        <TopMenuLinkSeparator/>
        <a href={'https://www.youtube.com/watch?v=J---aiyznGQ'} target='_blank' className="TopMenuLink">Keyboard Cat</a>


    </div>
}

