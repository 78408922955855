import React from 'react';
import '../components/HomeView/Home.css'
import {TopMenuLinks} from "../components/HomeView/TopMenuLinks";
import {TopMenuLogo} from "../components/HomeView/TopMenuLogo";
import pic1 from './../img/photographs/1.jpg'
import pic2 from './../img/photographs/2.jpg'
import pic3 from './../img/photographs/3.jpg'
import pic4 from './../img/photographs/4.jpg'
import pic5 from './../img/photographs/5.jpg'
import pic6 from './../img/photographs/6.jpg'
import pic7 from './../img/photographs/7.jpg'
import pic8 from './../img/photographs/8.jpg'
import pic9 from './../img/photographs/9.jpg'
import pic10 from './../img/photographs/10.jpg'

import pic11 from './../img/photographs/11.jpg'
import pic12 from './../img/photographs/12.jpg'
import pic13 from './../img/photographs/13.jpg'
import pic14 from './../img/photographs/14.jpg'
import pic15 from './../img/photographs/15.jpg'
import pic16 from './../img/photographs/16.jpg'
import pic17 from './../img/photographs/17.jpg'
import pic18 from './../img/photographs/18.jpg'
import pic19 from './../img/photographs/19.jpg'
import pic20 from './../img/photographs/20.jpg'

import pic21 from './../img/photographs/21.jpg'
import pic22 from './../img/photographs/22.jpg'
import pic23 from './../img/photographs/23.jpg'
import pic24 from './../img/photographs/24.jpg'
import pic25 from './../img/photographs/25.jpg'

import {MobileSearchBar} from "../components/HomeView/MobileSearchBar";

import AbbiePic from './../img/Abbie.jpg';
import {PhotographyItem} from "../components/PhotographyView/PhotographyItem";

export const PhotographyView = () => {

    return <div className='PageBackground'>
        <TopMenuLogo/>
        <MobileSearchBar/>
        <TopMenuLinks/>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            {/*message*/}
            <div style={{backgroundColor: 'rgb(48,93,150)', width: '100%', padding: '1%'}}>
                <div style={{textAlign: 'center', fontSize: 18, color: 'white'}}>Here are some of my favorite photographs that I've taken.
                </div>
            </div>
            <PhotographyItem name={'The Alaska Range  [Hasselblad 500 C/M]'} picture={pic25}/>

            <PhotographyItem name={'The Alaska Range [Hasselblad 500 C/M]'} picture={pic17}/>
            <PhotographyItem name={'On a Boat [Hasselblad 500 C/M]'} picture={pic24}/>
            <PhotographyItem name={'Not the Northern Lights [Holga 120N]'} picture={pic22}/>

            <PhotographyItem name={'Matanuska Glacier [Hasselblad 500 C/M]'} picture={pic20}/>
            <PhotographyItem name={'Departing Flight [Hasselblad 500 C/M]'} picture={pic18}/>
            <PhotographyItem name={'Exit Glacier [Hasselblad 500 C/M]'} picture={pic15}/>
            <PhotographyItem name={'Anchorage, Alaska [Hasselblad 500 C/M]'} picture={pic21}/>

            <PhotographyItem name={'The Alaska Range [Hasselblad 500 C/M]'} picture={pic16}/>

            <PhotographyItem name={'Whale Carving [Hasselblad 500 C/M]'} picture={pic14}/>
            <PhotographyItem name={'The Alaska Range [Hasselblad 500 C/M]'} picture={pic13}/>
            <PhotographyItem name={'Wrigley [Holga 120N]'} picture={pic11}/>
            <PhotographyItem name={'Alaska Botanical Garden [Holga 120N]'} picture={pic10}/>
            <PhotographyItem name={'Summer in Central Illinois [Holga 120N]'} picture={pic9}/>
            <PhotographyItem name={'Whittier, Alaska [Hasselblad 500 C/M]'} picture={pic8}/>
            <PhotographyItem name={'Matanuska Glacier [Hasselblad 500 C/M]'} picture={pic7}/>

            <PhotographyItem name={'Leah [Holga 120N]'} picture={pic3}/>
            <PhotographyItem name={'Alaska [Hasselblad 500 C/M]'} picture={pic2}/>


        </div>
    </div>
}

