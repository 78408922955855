import React from 'react'
import {TopMenuLinkSeparator} from "./TopMenuLinkSeparator";
import myPhoto from './../../img/me.jpg';
import myPhotoFavorited from './../../img/meLove.png';
import './ProfilePictureSection/ProfilePictureSection.css';
export const ProfilePictureSection = (props) => {

    const ProfilePicture = () => {
        if(props.showFavoritedExtendedNetworkMessage === true){
            return <img src={myPhotoFavorited} style={{minWidth: '225px'}} width={'100%'}/>
        } else {
            return <img src={myPhoto} style={{minWidth: '225px'}} width={'100%'}/>
        }
    }
    let getCurrentDate = () => {
        let newDate = new Date()
        let date = newDate.getDate();
        date = date < 10 ? '0' + date : date;
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        return `${month<10?`0${month}`:`${month}`}/${date}/${year}`
    }

    return <div className='profilePictureSectionContainer'>
        <div style={{
            textAlign: 'left',
            flex: '0 0 100%',
            display: 'flex',
            fontWeight: 'bold',
            fontSize: 20,
            paddingTop: '1%',
            paddingBottom: '1%'
        }}>Matt
        </div>
        <div style={{flexDirection: 'row', display: 'flex'}}>
            <div style={{
                flex: .7,
            }}>
                <ProfilePicture/>
            </div>
            <div style={{
                flex: 1,
            }}>
                {/*text container*/}
                <div style={{marginLeft: '5%'}
                }>
                    {/*quote*/}
                    <div>
                        ":-)"
                    </div>
                    {/*bio*/}
                    <div style={{paddingTop: '5%'}}>
                        Male<br />
                        33 Years old<br />
                        San Diego,<br />
                        CALIFORNIA<br />
                        United States
                    </div>
                    {/*last login*/}
                    <div style={{paddingTop: '5%'}}>
                        Last Login:<br />
                        {getCurrentDate()}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

