import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {HomeView} from "./HomeView";
import {PaintingView} from "./PaintingView";
import {ProgrammingView} from "./ProgrammingView";
import {PageNotFoundView} from "./PageNotFoundView";
import {HelpView} from "./HelpView";
import LoginView from "./LoginView";
import {PortraitsView} from "./PortraitsView";
import {VideosView} from "./VideosView";
import {ComingSoonView} from "./ComingSoonView";
import {RecipeListView} from "./RecipeListView";
import {RecipeView} from "./RecipeView";
import {ScubaView} from "./ScubaView";
import {PhotographyView} from "./PhotographyView";
import {ImATeapotView} from "./ImATeapotView";
import {ResumeView} from "./ResumeView";

const MjfreWebsite = () => {

    return (
        <div style={{height: '100%'}}>
            <BrowserRouter>
                <Routes>
                    <Route path="/resume" element={<ResumeView/>}/>
                    <Route path="/instant-messenger" element={<ImATeapotView/>}/>
                    <Route path="/photography" element={<PhotographyView/>}/>
                    <Route path="/recipes" element={<RecipeListView/>}/>
                    <Route path="/recipe" element={<RecipeView/>}/>
                    <Route path="/scuba" element={<ScubaView/>}/>
                    <Route path="/videos" element={<VideosView/>}/>
                    <Route path="/pics" element={<PortraitsView/>}/>
                    <Route path="/sign_in" element={<LoginView/>}/>
                    <Route path="/help" element={<HelpView/>}/>
                    <Route path="/painting" element={<PaintingView/>}/>
                    <Route path="/programming" element={<ProgrammingView/>}/>
                    <Route path="/not_found" element={<PageNotFoundView/>}/>
                    <Route path="/index.html" element={<Navigate to="/" replace/>}/>
                    <Route path="/" exact element={<HomeView/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default MjfreWebsite
