import React from 'react'
import './FriendsCommentsItem.css'

export const FriendsCommentsItem = (props) => {

    return <div style={{
        backgroundColor: "pink",
        flexDirection: 'row',
        display: 'flex',
        marginTop: '.25%'
    }}>
        {/*image and name*/}
        <div className='LeftSideContainer'>
            <div style={{color: 'rgb(48,93,150)'}}>{props.name}</div>
            <img src={props.picture} className='FriendCommentImage'/>
        </div>
        {/*comment body*/}
        <div className='RightSideContainer'>
            <div style={{fontWeight:'bold'}}>{props.title}</div>
            <div style={{marginTop: '2.5%'}}>{props.body}</div>
        </div>
    </div>
}

