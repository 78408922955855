import React from 'react'

export const PaintingItem = (props) => {

    return <div style={{
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1%',
        marginTop: '1%',
        display: 'flex',
        flex: 1,
        width: '100%',
        flexBasis: '600px',
        maxWidth: '800px'
    }}>
        <div style={{
            flexDirection: 'column',
            display: 'flex',
            flex: 1,
            width: '100%',
            padding: '5%'
        }}>
            <div style={{color:'rgb(48,93,150)', fontWeight: 'bold'}}>{props.name}</div>
            <img src={props.picture} width={'100%'} style={{ border: '2px solid rgb(48,93,150)'}}/>
        </div>
    </div>
}

