import React, {useState} from 'react'
import {TopMenuLinkSeparator} from "./TopMenuLinkSeparator";
import {websiteUrl} from "../../service/_BackendUrls";

import {useNavigate} from "react-router-dom";
import './../TopMenuLogo/TopMenuLogo.css';

export const TopMenuLogo = (props) => {

    const [searchValue, setSearchValue] = useState('');

    const navigate = useNavigate();
    const routeChange = () => {
        navigate("/not_found", {state:{searchValue: searchValue}});
    }

        return <div className='containerTop'>
            <div style={{display: 'flex', flexDirection: 'row', flex: 1, width: '100%'}}>
                <div className='topMenuLeftSideContainer'>
                    <div style={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>MattSpace |</div>
                    <a href={websiteUrl}
                       className="TopMenuLink" style={{fontSize: 14, display: 'flex', padding: 0, margin: 0, alignItems: 'center', height: '100%', marginLeft: '1%',}}>Home</a>
                </div>
            </div>
            <div className='searchBarContainer'>
                <input style={{width: '60%'}} name="myInput" placeholder={'Search for Friends'} value={searchValue}
                       onChange={e => setSearchValue(e.target.value)} onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        routeChange();
                    }
                }
                }/>
                <button style={{marginLeft: '2.5%', borderRadius: 0, WebkitAppearance: 'none', backgroundColor: 'rgb(239,239,239)'}} onClick={() => routeChange()}>Search</button>
            </div>
            <div className='topMenuRightSideContainer'>
                <div style={{
                    fontSize: 14,
                    color: 'white',
                    paddingLeft: '2.5%',
                    textAlign: 'right',
                    marginLeft: 'auto',
                    marginRight: '5%',
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <a href={websiteUrl + '/help'}
                       className="TopMenuLink" style={{
                        fontSize: 14,
                        display: 'flex',
                        padding: 0,
                        margin: 0,
                        alignItems: 'center',
                        height: '10%',
                        marginRight: '5%'
                    }}>Help</a>
                    <div style={{
                        fontSize: 14,
                        display: 'flex',
                        padding: 0,
                        margin: 0,
                        alignItems: 'center',
                        height: '10%'
                    }}>|
                    </div>
                    <a href={websiteUrl + '/sign_in'}
                       className="TopMenuLink" style={{
                        fontSize: 14,
                        display: 'flex',
                        padding: 0,
                        margin: 0,
                        alignItems: 'center',
                        height: '10%',
                        marginLeft: '5%'
                    }}>SignOut</a>
                </div>
            </div>
        </div>
    }

