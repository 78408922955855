import React from 'react'
import {ContactItem} from "./ContactingMe/ContactItem";
import {DetailsItem} from "./Details/DetailsItem";

export const Details = (props) => {

    if(props.showNotFriendsExtendedNetworkMessage === true || props.showBlockedExtendedNetworkMessage === true){
        return null;
    } else {
        return <div style={{
            border: '2px solid rgb(48,93,150)',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'left',
            margin: '2.5%',
            marginTop: '5%',
        }}>
            <div style={{
                display: 'flex',
                flexFlow: 'row wrap', alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '2%'
            }}>
                <div style={{
                    textAlign: 'left',
                    flex: '0 0 100%',
                    display: 'flex',
                    paddingTop: '1%',
                    paddingBottom: '1%',
                    paddingLeft: '2.5%',
                    color: 'white',
                    fontWeight: 'bold',
                    backgroundColor: 'rgb(113,152,202)',
                }}>Matt's Details
                </div>
                <div style={{display: 'flex', width: '100%', marginTop: '2.5%', flexDirection: 'column'}}>
                    <DetailsItem label={'Description'} value={'I\'m tall'}/>
                    <DetailsItem label={'Here For'} value={'Friends, Networking'}/>
                    <DetailsItem label={'Body Type'} value={'I\'m tall'}/>
                    <DetailsItem label={'Zodiac Sign'} value={'Leo'}/>
                    <DetailsItem label={'Education'} value={'Bachelor\'s Degree'}/>
                    <DetailsItem label={'Occupation'} value={'LIVING'}/>
                    <DetailsItem label={'Music'}
                                 value={'Alpha Blondy, Sam Cooke, Masayoshi Takanaka, Franzl Lang, Al Green, Sublime, Pavel Chesnokov, Barrington Levy, Keb Mo, Wade Bowen, The Stanley Brothers, Slightly Stoopid'}/>


                </div>
            </div>
        </div>
    }
}

